import React from 'react'
import Layout from '../components/layout'
import Pricing from '../components/Pricing'

const PricingPage = () => (
  <Layout>
    <Pricing />
  </Layout>
)

export default PricingPage

import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Helmet } from "react-helmet"
import HeaderSecondary from "../headerSecondary"
import Footer from "../footer"
import Collapsible from "react-collapsible"
import BlurShape from "../../images/blur-shape.svg"
import "../global.scss"
import "./style.scss"
import { signUpUrl } from "../../utils/constants"

class Pricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Pricing</title>
          <meta
            name="description"
            content="A standout web presence, a simple CRM, easy to use marketing tools, and a powerful listing management platform with IDX and marketplace syndication all built in and all in one place."
          />
        </Helmet>
        {/* <div className="top-shape"><img src={TopShape}/></div> */}
        <HeaderSecondary />
        <div className="pricing-page">
          <Container>
            <Row>
              <Col>
                <div className="banner-head">
                  <div className="blur-shape">
                    <img src={BlurShape} alt=""/>
                  </div>
                  <div className="sub-heading">
                    <h1 className="title-heading">Supercharge Your Closings</h1>
                    <p className="subtitle-heading">
                      You know how a spiffy presentation can help close deals. Now
                      it’s time to spruce up your digital presence with a
                      high-tech site and an intelligent CRM that keeps on top of
                      your leads. Let’s Get After It.
                    </p>
                  </div>
                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="pricing-card-row">
                  <div className="pricing-card">
                    <div className="pricing-years">Monthly</div>
                    <div className="pricing-amount">
                      <span className="pricing-doller">$</span>39<span className="pricing-duration">/mo</span>
                    </div>
                    <div className="pricing-total">billed monthly</div>
                    <a
                      href={signUpUrl}
                      className="btn btn-trial-start"
                    >
                      Start Free Trial
                    </a>
                  </div>
                  <div className="pricing-card">
                    <div className="pricing-years">Annual - Save 25%</div>
                    <div className="pricing-amount">
                      <span className="pricing-doller">$</span>351<span className="pricing-duration">/yr</span>
                    </div>
                    <div className="pricing-total">
                      billed annually
                    </div>
                    <a
                      href={signUpUrl}
                      className="btn btn-trial-start"
                    >
                      Start Free Trial
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div className="panel-wrapper">
                  <h2 className="panel-title">Frequently Asked Questions</h2>
                  <Collapsible
                    trigger="Can someone from the Yardpost team help me setup my site?"
                    classParentString="yp-panel"
                    openedClassName="open"
                    transitionTime="200"
                  >
                    <p>
                      Yes. While it's super easy to do it yourself, if you get
                      stuck or want our opinion we offer FREE setup of all
                      sites. We'll have one of our dedicated technical
                      associates setup your Yardpost site with you.
                    </p>
                  </Collapsible>
                  <Collapsible
                    trigger="Do I need a web host?"
                    classParentString="yp-panel"
                    openedClassName="open"
                    transitionTime="200"
                  >
                    <p>
                      All Yardpost sites are managed on AWS Cloud Infrastructure
                      that has one of the highest uptime guarantees in the world
                      (more than 99%). This ensures your site will always be
                      available for your clients.
                    </p>
                  </Collapsible>
                  <Collapsible
                    trigger="How do I cancel my service?"
                    classParentString="yp-panel"
                    openedClassName="open"
                    transitionTime="200"
                  >
                    <p>
                      You can easily cancel your Yardpost account from the
                      account information section in your dashboard.
                    </p>
                  </Collapsible>
                  <Collapsible
                    trigger="Don’t see your question? "
                    classParentString="yp-panel"
                    openedClassName="open"
                    transitionTime="200"
                  >
                    <p>
                      We’ve made it extremely easy and intuitive to setup your
                      Yardpost site. And if you get stuck at any point or have
                      questions, we also offer Free site setup. Also, our
                      technical team is always on standby to answer your
                      questions and assist you with your site.
                    </p>
                  </Collapsible>
                </div>
              </Col>
            </Row>
          </Container>
          <section className="start-trial-section">
            <Container>
              <Row>
                <Col lx="12" lg="12" md="12" sm="12" xs="12">
                  <div className="start-trial-wrapper">
                    <h2 className="title">
                      Start your free 14-day trial now
                    </h2>
                    <a
                      href={signUpUrl}
                      className="btn btn-start-trail"
                    >
                      Get Started
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
          {/* <div className="bottom-shape"><img src={BottomShape}/></div> */}
        </div>
      </>
    )
  }
}

export default Pricing
